<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 ml-5 flex items-center justify-between px-6">
      <h2>{{ Object.entries(this.data).length === 0 ? "Add New" : "Edit" }} Early Learning Framework</h2>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>
    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">

        <!-- NAME -->
        <div class="w-full">
          <label class="block mb-2">Title</label>
          <vs-input v-model="title" class="w-full" name="name" v-validate="'required'"/>
          <span class="text-danger text-sm" v-show="errors.has('title')">{{ errors.first('title') }}</span>
        </div>

        <!-- NAME -->
        <div class="mt-5 w-full">
          <label class="block mb-2">Description</label>
          <vs-textarea v-model="description" name="description" v-validate="'required'"/>
          <span class="text-danger text-sm" v-show="errors.has('description')">{{ errors.first('description') }}</span>
        </div>

        <div class="vx-col w-full">
          <label class="block mb-2">Type</label>
          <ul class="demo-alignment">
            <li v-for="(category, key) in categories" :key="key">
              <vs-radio vs-name="category" v-model="categoryId" :vs-value="category._id">{{ category.title }}</vs-radio>
            </li>
            <!--            <li>-->
            <!--              <vs-radio vs-name="type1" v-model="type" vs-value="practices">Practices</vs-radio>-->
            <!--            </li>-->
            <!--            <li>-->
            <!--              <vs-radio vs-name="type1" v-model="type" vs-value="outcomes">Outcomes</vs-radio>-->
            <!--            </li>-->
            <!--            <li>-->
            <!--              <vs-radio vs-name="type1" v-model="type" vs-value="principles">Principles</vs-radio>-->
            <!--            </li>-->
          </ul>
        </div>

      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center justify-between p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Submit</vs-button>
      <vs-button class="vs-button-secondary" @click="isSidebarActiveLocal = false">Cancel</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {mapActions} from "vuex";

export default {
  data() {
    return {
      dataId: null,
      title: "",
      type: "practices",
      categoryId: '',
      description: "",
      dataStateId: "",
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60,
      },
    }
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    stateId: {
      type: String,
      required: true
    },
    eylfSetId: {
      type: String,
      required: true
    },
    categories: {
      type: Array,
    },
    category: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      default: () => {
      },
    },
  },

  watch: {
    isSidebarActive(val) {
      this.dataStateId = this.stateId;
      this.dataEylfSetId = this.eylfSetId;
      this.categoryId = this.category;
      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        this.dataId = this.data._id
        this.title = this.data.title
        this.type = this.data.type
        this.description = this.data.description
        this.categoryId = this.data.categoryId
        this.initValues();
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    },
  },

  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid() {
      return !this.errors.any() && this.title && this.description && this.categoryId
    }
  },
  methods: {
    ...mapActions("earlyYearLearningFramework", [
      "updateEylf",
      "storeEylf",
    ]),
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: color
      })
    },
    initValues() {
      if (this.data._id) return
      this.dataId = null
      this.title = ""
      this.type = "practices"
      this.description = ""
      // this.categoryId = ''
    },
    async submitData() {
      await this.$validator.validateAll().then(async result => {
        if (result) {
          const data = {
            eylfId: this.dataId,
            title: this.title,
            categoryId: this.categoryId,
            // type: this.type,
            description: this.description,
            region: this.dataStateId,
            eylfFrameworkSet: this.dataEylfSetId,
          }

          this.$vs.loading()

          if (this.dataId !== null) {
            await this.updateEylf(data)
              .then((res) => {
                this.$vs.loading.close()
                this.showMessage("Success", "ELYF updated successfully.", "success");
              })
              .catch(err => {
                this.$vs.loading.close()
                console.error(err)
              })
          } else {

            await this.storeEylf(data)
              .then((res) => {
                this.$vs.loading.close()
                this.showMessage("Success", "ELYF added successfully.", "success");
              })
              .catch(err => {
                this.$vs.loading.close()
                console.error(err)
              })
          }
          this.$emit('refreshData')

          this.$emit('closeSidebar')
          this.initValues()
          this.errors.clear();
          this.$nextTick(() => {
            this.$validator.reset();
          });
        }
      })
    },
  },
  components: {
    VuePerfectScrollbar,
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
