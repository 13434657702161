<template>
  <div>
    <EylfForm :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar"
              :data="sidebarData"
              :stateId="stateId" :eylfSetId="eylfSetId" :categories="categories" :category="categoryId"/>
    <vx-card>
      <div class="op-block mt-0 rounded-none shadow-none">
        <vs-row>
          <vs-col vs-type="flex" vs-w="5">
            <ul class="demo-alignment">
              <li v-for="(category, key) in categories" :key="key">
                <vs-radio vs-name="categoryId" v-model="categoryId" :vs-value="category._id"
                          @change="onChangeType(category)">{{ category.title }}
                </vs-radio>
              </li>
              <!--              <li>-->
              <!--                <vs-radio vs-name="type" v-model="type" vs-value="practices"-->
              <!--                          @change="onChangeType($event)">Practices-->
              <!--                </vs-radio>-->
              <!--              </li>-->
              <!--              <li>-->
              <!--                <vs-radio vs-name="type" v-model="type" vs-value="outcomes"-->
              <!--                          @change="onChangeType($event)">Outcomes-->
              <!--                </vs-radio>-->
              <!--              </li>-->
              <!--              <li>-->
              <!--                <vs-radio vs-name="type" v-model="type" vs-value="principles"-->
              <!--                          @change="onChangeType($event)">Principles-->
              <!--                </vs-radio>-->
              <!--              </li>-->
            </ul>
          </vs-col>
        </vs-row>
        <vs-divider/>
        <vs-row>
          <vs-col vs-justify="center" vs-align="center" vs-lg="2" vs-sm="3" vs-xs="6">
            <p class="h4">{{ eylfTypeText | capitalize }}</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-lg="9" vs-sm="7" vs-xs="6">
            <p class="h4">Description</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="left" vs-lg="1" vs-sm="2" vs-xs="12">
            <vs-button class="mr-sm-4 mr-0 mt-sm-0 mt-4" @click="addNewData">Add</vs-button>
          </vs-col>
          <vs-divider/>
        </vs-row>

        <vs-row :key="index" v-for="(item,index) in eylfChecklists">
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-lg="2" vs-sm="3" vs-xs="12">
            <p class="font-bold h5">{{ eylfTypeText }} {{ index + 1 }}</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="9" vs-lg="9" vs-sm="7" vs-xs="12">
            <p class="">
              <span class="font-bold h5">{{ item.title }}</span> <br>
              {{ item.description }}
            </p>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="center" vs-align="left" vs-lg="1" vs-sm="2" vs-xs="12">
            <a href="#" @click.stop="editData(item)">
              <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current"
              />
            </a>
            <a href="#" @click.stop="deleteData(item._id)">
              <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current"
                            class="ml-2"/>
            </a>
          </vs-col>
          <vs-divider/>
        </vs-row>
      </div>
    </vx-card>

  </div>
</template>
<script>
import EylfForm from "./EylfForm";
import Swal from 'sweetalert2/dist/sweetalert2';
import 'sweetalert2/src/sweetalert2.scss'
import {mapActions} from "vuex";

export default {
  components: {EylfForm},
  data() {
    return {
      countryId: '',
      categoryId: '',
      path: '',
      selected: [],
      eylfChecklists: [],
      stateId: "",
      eylfSetId: this.$route.params.id,
      type: "outcome",
      eylfTypeText: "Outcomes",
      isMounted: false,

      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},
      categories: []
    }
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },

    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.coreSkills.length
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  methods: {
    ...mapActions("eylfCategory", [
      "fetchEYLFCategoryByCountryId",
      "fetchEYLFCategoryByStateId"
    ]),
    ...mapActions("earlyYearLearningFramework", [
      "getEylfSetById",
      "deleteEylf",
      "getEylfs",
      "setEylfType",
      "getStateInfo",
      "setEylfCategory"
    ]),
    onChangeType(data) {
      // if (this.type == "practices") {
      //   this.eylfTypeText = "PRACTICE";
      // } else if (this.type == "outcomes") {
      //   this.eylfTypeText = "OUTCOME";
      // } else if (this.type == "principles") {
      //   this.eylfTypeText = "PRINCIPLE";
      // }
      this.eylfTypeText = data.title;
      this.setEylfType(this.type);
      this.setEylfCategory(data._id);
      this.getEylf();
    },
    async getEylf() {
      const filter = {
        stateId: this.stateId,
        categoryId: this.categoryId,
        // type: this.type
      };
      this.$vs.loading()
      await this.getEylfs(filter)
        .then((res) => {
          this.$vs.loading.close()
          this.eylfChecklists = this.$store.state.earlyYearLearningFramework.eylfChecklists;
        })
        .catch(err => {
          this.$vs.loading.close()
          console.error(err)
        })
    },
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: color
      })
    },
    addNewData() {
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },
    deleteData(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.delete(id)
        }
      })

    },
    async delete(id) {

      this.$vs.loading()
      this.deleteEylf(id)
        .then((res) => {
          this.$vs.loading.close()
          this.showMessage("Success", "ELYF deleted successfully.", "success");
          this.eylfChecklists = this.$store.state.earlyYearLearningFramework.eylfChecklists;
        })
        .catch(err => {
          this.$vs.loading.close()
          console.error(err)
        })
    },
    editData(data) {
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
    toggleDataSidebar(val = false) {
      this.getEylf();
      this.addNewDataSidebar = val
    },
    async getCategories(stateId) {
      await this.fetchEYLFCategoryByStateId(stateId).then((res) => {
        this.categories = res.data.data;
        console.log(this.categories[0]);
        this.eylfTypeText = this.categories[0].title
        this.categoryId = this.categories.length > 0 ? this.categories[0]._id : '';
      }).catch(error => console.log(error));
    }
  },
  async created() {
    this.$vs.loading.close()
    await this.getEylfSetById(this.eylfSetId)
      .then((res) => {
        this.stateId = res.data.data.stateID
        this.stateName = res.data.data.state
        this.countryId = res.data.data.country ? res.data.data.country._id : '';
      })
      .catch(err => {
        this.$vs.loading.close()
        console.error(err)
      })
    await this.getCategories(this.stateId);

    await this.setEylfType(this.type);
    await this.getEylf();

    await this.$emit('changeBreadcrumbTitle', this.stateName);
  }

}
</script>
